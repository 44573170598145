import axios from 'axios'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import url from '@/utils/URL'
import { ElMessageBox, ElMessage, ElLoading } from 'element-plus'

var toast
var isLoading

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: url.target,
  // 超时
  timeout: 20000

})
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  isLoading = (config.headers || {}).loading === false
  const dataType = (config.headers || {}).dataType === false
  if (getToken() && !isToken) {
    config.headers.Authorization = 'Bearer ' + getToken()
    // config.headers.Authorization = 'appBearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  if (dataType) {
    console.log('dataType', dataType)
    config.headers['Content-Type'] = 'multipart/form-data'
    console.log(config.headers)
  }
  if (!isLoading) {
    toast = ElLoading.service({
      lock: true,
      text: 'loading...',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    setTimeout(() => {
      toast.close()
    }, 500)
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?'
    for (const propName of Object.keys(config.params)) {
      const value = config.params[propName]
      var part = encodeURIComponent(propName) + '='
      if (value !== null && typeof (value) !== 'undefined') {
        if (typeof value === 'object') {
          for (const key of Object.keys(value)) {
            if (value[key] !== null && typeof (value[key]) !== 'undefined') {
              const params = propName + '[' + key + ']'
              const subPart = encodeURIComponent(params) + '='
              url += subPart + encodeURIComponent(value[key]) + '&'
            }
          }
        } else {
          url += part + encodeURIComponent(value) + '&'
        }
      }
    }
    url = url.slice(0, -1)
    config.params = {}
    config.url = url
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  if (!isLoading) {
    // toast.clear()
  }

  // 未设置状态码则默认成功状态
  const code = res.data.code || 200
  // 获取错误信息
  const msg = errorCode[code] || res.data.msg || errorCode.default
  if (code === 401) {
    ElMessageBox.confirm(
      '登录状态已过期，您可以继续留在该页面，或者重新登录',
      '系统提示',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
    ).then(() => {
      store.dispatch('LogOut').then(() => {
        location.href = '/'
      })
    })
      .catch(() => {

      })
    return Promise.reject(new Error('无效的会话，或者会话已过期，请重新登录。'))
  } else if (code === 500) {
    if (res.data.data != null) {
      return res.data
    }
    ElMessage.error(msg)
    return Promise.reject(new Error(msg))
  } else if (code !== 200 && code !== 410) {
    ElMessage.error(msg)
    return Promise.reject(new Error('error'))
  } else {
    return res.data
  }
},
error => {
  console.log('err' + error)
  let { message } = error
  if (message === 'Network Error') {
    message = '后端接口连接异常'
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时'
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常'
  }
  ElMessage.error(message)

  return Promise.reject(error)
}
)

export default service
