<template>
  <template v-if="navShow">
    <Header
      v-show="navShow"
      :title="title"
    ></Header>
  </template>
  <router-view v-slot="{ Component }">
    <keep-alive :key="$route.path">
      <component
        :is="Component"
        v-if="$route.meta.keepAlive"
      />
    </keep-alive>
    <component
      :is="Component"
      v-if="!$route.meta.keepAlive"
    />
  </router-view>
</template>
<script setup name="App">
import { ref } from '@vue/reactivity'
import { onMounted, watchEffect } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import Header from './components/Header.vue'

const navShow = ref(false)
const title = ref('')
const route = useRoute()

onMounted(() => {
  console.log(route.meta.navShow)
  navShow.value = route.meta.navShow
  title.value = route.meta.title
})
watchEffect(async () => {
  navShow.value = route.meta.navShow
  title.value = route.meta.title
})

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
