'use strict'
// 跨域请求的地址
var domain = '/api'

// 服务端地址
// var target = 'http://192.168.120.156:8089/'
var baseUrl = 'http://192.168.0.105:8080'
// var target = 'http://192.168.1.13:8089'
var target = 'https://api.ecocarbontrace.com'
// 正式环境
// var target = 'http://192.168.120.161:8080'

// var target = 'http://127.0.0.1:8098'
// 正式环境
var socketUrl = 'wss://api.xxx.com/wss'
// var socketUrl = 'ws://localhost:9696/ws'

// 前端页面的地址
var fontUrl = 'https://pc.ygsg.org/'

module.exports = {
  domain: domain,
  target: target,
  baseUrl: baseUrl,
  fontUrl: fontUrl,
  socketUrl: socketUrl
}
